.btn {
    outline: none;
    cursor: pointer;
    font-family: Marcellus;
    font-size: 14px;
    line-height: 36px;
    letter-spacing: 1px;
    padding: 4px 30px;
    color: #c02323;
    border: 1px solid #c02323;
    display: inline-block;
    background-color: #fff;
}


.btn:active {
    transform: scale(0.98);
    /* Scaling button to 0.98 to its original size */
    box-shadow: 3px 2px 5px 2px rgba(192, 35, 35, 0.618);
    /* Lowering the shadow */
}

.disabled:active {
    color: #c02323;
    transform: none;
    box-shadow: none;
}

.disabled {
    opacity: 0.6;
    cursor: not-allowed;
}






