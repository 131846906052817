
.TabReview {
    display: flex;
    margin-top: 72px;

    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    align-content: center;

}

.CheckOut {
    width: 66.66%;
    padding: 10px 0px;
    border: 1px solid #a6a6a6;
    float: left;

}

.CheckOut > h3 {
    position: relative;
    text-align: center;
    font-size:16px;
    line-height: 20px;
    font-family: "Marcellus", sans-serif;
    font-weight: 400;
    color: #191919;
}

.CheckOut > h3::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #a6a6a6;
}

.CheckOutForm {
    display: block;
    padding: 20px 30px;
}

.CheckOutTwoFieldsOnALine {
    display: block;
    margin-bottom: 9px;
    height: 81px;
}

.CheckOutFirstFieldOnALine > p {
    font-family: 'Libre Baskerville', sans-serif;
    line-height:  36px;
    font-size: 12px;
    font-weight: normal;

    color: #c02323;
}

.CheckOutFirstFieldOnALine {
    padding-right: 10px;
    float: left;
    width: 50%;
}

.CheckOutFirstFieldOnALine > label {
    display: inline-block;
    margin-bottom: 3px;

    font-family: 'Libre Baskerville', sans-serif;
    line-height:  36px;
    font-size: 14px;
    font-weight: normal;
}

.CheckOutFirstFieldOnALine > input {
    width: 100%;
    float: left;


    display: block;
    position:relative;
    
    font-family: 'Libre Baskerville', sans-serif;
    line-height:  36px;
    font-size: 14px;
    font-weight: normal;

    padding: 5px 15px;
    border: 1px solid #e5e5e5;
    height: 46px;
}

.CheckOutSecondFieldOnALine {
    padding-left: 10px;
    float: right;
    width: 50%;
}

.CheckOutSecondFieldOnALine > label {
    display: inline-block;
    margin-bottom: 3px;

    font-family: 'Libre Baskerville', sans-serif;
    line-height:  36px;
    font-size: 14px;
    font-weight: normal;
}


.CheckOutSecondFieldOnALine > input {
    width: 100%;
    float: left;

    position:relative;
    display: block;

    font-family: 'Libre Baskerville', sans-serif;
    line-height:  36px;
    font-size: 14px;
    font-weight: normal;

    padding: 5px 15px;
    border: 1px solid #e5e5e5;
    height: 46px;

    transition: all 0.3s ease-in-out;
}


.CheckOutSecondFieldOnALine > p {
    font-family: 'Libre Baskerville', sans-serif;
    line-height:  36px;
    font-size: 12px;
    font-weight: normal;

    color: #c02323;
}

.CheckOutInputField {
    margin-top: 9px;
    display: block;
    height: 81px;
    margin-bottom: 9px;
}


.CheckOutInputField > label {
    font-display: inline-box;
    margin-bottom: 3px;

    font-family: 'Libre Baskerville', sans-serif;
    line-height:  36px;
    font-size: 14px;
    font-weight: normal;
}


.CheckOutInputField > input {
    width: 100%;
    float: left;

    position:relative;
    display: block;

    font-family: 'Libre Baskerville', sans-serif;
    line-height:  36px;
    font-size: 14px;
    font-weight: normal;

    padding: 5px 15px;
    border: 1px solid #e5e5e5;
    height: 46px;
}

.CheckOutInputField > input:focus {
    outline: none; 
    border: 1px solid #c02323;
}

.CheckOutInputField > p { 
    font-family: 'Libre Baskerville', sans-serif;
    line-height:  36px;
    font-size: 12px;
    font-weight: normal;

    color: #c02323;
}

.CheckOutAgreeTermsAndConditions {
    margin-top: 18px;
    height: 51px;
}


.CheckOutAgreeTermsAndConditions > input{
    float: left;

    position:relative;
    display: block;

    transform: scale(1.5);
    accent-color: #c02323;
    margin-top: 9px;
    margin-right: 9px;
    margin-left: 3px;
}

.CheckOutAgreeTermsAndConditions > label {
    font-display: inline-box;
    margin-bottom: 3px;

    font-family: 'Libre Baskerville', sans-serif;
    line-height:  36px;
    font-size: 14px;
    font-weight: normal;
}

.CheckOutAgreeTermsAndConditions > p { 
    font-family: 'Libre Baskerville', sans-serif;
    line-height:  36px;
    font-size: 12px;
    font-weight: normal;

    color: #c02323;
}

.CheckOutTermsAndConditionsLink {
    text-decoration: none;
    font-family: 'Marcellus';
    font-size: 16px;
    line-height: 36px;
    color: #c02323;
}


.CheckOutInputFieldNoError {
    margin-top: 9px;
    display: block;
    height: 81px;
    margin-bottom: 9px;
}

.CheckOutInputFieldNoError > label 
{
    font-display: inline-box;
    margin-bottom: 3px;

    font-family: 'Libre Baskerville', sans-serif;
    line-height:  36px;
    font-size: 14px;
    font-weight: normal;
}

.CheckOutInputFieldNoError > input {
    width: 100%;
    float: left;

    position:relative;
    display: block;

    font-family: 'Libre Baskerville', sans-serif;
    line-height:  36px;
    font-size: 14px;
    font-weight: normal;

    padding: 5px 15px;
    border: 1px solid #e5e5e5;
    height: 46px;
}

.CheckOutInputFieldNoError > input:focus {
    outline: none; 
    border: 1px solid #c02323;
}

.CheckOutInputFieldNoError > p { 
    font-family: 'Libre Baskerville', sans-serif;
    line-height:  36px;
    font-size: 12px;
    font-weight: normal;

    color: #c02323;
}

.BiggerHeight {
    height: 114px;
}

.AddressBiggerHeight {
    height: 108px;
}

.ReviewOrder {
    width: 33.33%;
    float: right;
    padding: 0 0 0 20px;
}

.OrderTable{
    position: absolute;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    min-width: 380px;

}

.ReviewOrder > h3 {
    position: relative;
    text-align: center;
    font-size:16px;
    line-height: 20px;
    font-family: "Marcellus", sans-serif;
    font-weight: 400;
    color: #191919;

    padding: 10px 0;
    border: 1px solid #a6a6a6;
}

.ReviewOrderTable{
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}


.ReviewOrderTbody {
    width: 100%;
}

.OrderTableRow {
    border-bottom: none;
    font-family: 'Libre Baskerville', sans-serif;
    line-height:  24px;
    font-size: 14px;
    font-weight: normal;
    color: #595959;
}

.OrderTableRowBorder {
    border-bottom: none;
    font-family: 'Libre Baskerville', sans-serif;
    line-height:  24px;
    font-size: 14px;
    font-weight: normal;
    color: #595959;
    border-bottom: 1px solid #c2c2c2;
}

.ProductName {
    font-family: inherit;
    margin-left: 15px;
    font-size: 14px;
    line-height: 21px;
    padding: 25px 40px 24px 10px;
    vertical-align: baseline;

    box-sizing: border-box;
}

.ProductTotal {
    padding: 0px 5px 0 48px;
    font-size: 100%;
    font-weight: inherit;
    font-style: inherit;
    vertical-align: baseline;
    text-align: right;
}

.OrderTOTAL {
    font-family: inherit;
    margin-left: 15px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    padding: 25px 40px 24px 10px;
    vertical-align: baseline;

    box-sizing: border-box;
}

.CheckOutButton {
    margin-top: 24px;
    float: right;
}


@media screen and (max-width: 960px) {
    .CheckOutTwoFieldsOnALine {
        height: 81px;
        margin-bottom: 0px;
    }

    .BiggerHeight {
        height: 168px;
    }

    .AddressBiggerHeight {
        height: 108px;
    }

    .TermsAndConditionsBiggerHeight {
        height: 81px;
    }

    .TabReview {
        display: flex;
        flex-direction: column;
        width: 99%;
        margin-top: 72px;
        margin-left: auto;
        margin-right: auto;
    }
  

    .ReviewOrder {
        width: 99%;
        float: none;
        padding: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
    }

    .CheckOut {
        width: 100%;
    }
}