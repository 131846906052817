.Nav-Wrap {
    margin-bottom: 10px;
}

.NavbarItems {
    /*background: linear-gradient(90deg, rgb(110, 94, 254) 0%, rgba(73,63,252,1) 100% );*/
    background: #ffffff;
    height: 88px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}


.navbar-logo {
    color: #ffffff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
}

.fa-react {
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px; 
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
}

.nav-links {
/*    color: white;
    text-decoration: none;
    padding: 00.5rem 1rem;
*/
    font-family: 'Marcellus';
    font-size: 14px;
    line-height: 26px;
    color: #191919;
    letter-spacing: 1.4px;
    text-decoration: none;
    padding-left: 38px;
    padding-right: 38px;
}

.nav-links:hover {
/*    background-color:  #6d76f7;
    border-radius: 4px;
    transition: all 0.2s ease-out;
    */
    color :#c02323;
}

.nav-links:active {
    color :#c02323;
}

    
.fa-bars {
    color: #191919;
}

.nav-links-mobile {
    display: none;
}

.menu-icon {
    display: none;
}

.menu-cart {
    display: none;
}

@media screen and (max-width: 960px) {
    .NavbarItems {
        position: relative;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 150px;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        left: 0;
        transition: all 0.5s ease;
        z-index: 100;
        background-color: rgba(255, 255, 255, 1);        

    }

    .nav-links {
        text-align: center;
        padding: 2 rem;
        width: 100%;
        display: table;
        font-family: 'Marcellus';
        font-size: 14px;
        line-height: 26px;
        color: #191919;
        letter-spacing: 1.4px;
        text-decoration: none;
    }

    .nav-link-to-cart {
        margin-top: 12px;
        margin-left: 3px;
        text-align: center;
        width: 100%;
        display: inline-block;
        font-family: 'Marcellus';
        text-decoration: none;
        font-size: 1.6rem;
        cursor: pointer;
        color: #191919;
        background: transparent;    }

    .nav-links:hover {
        background-color: transparent;
        border-radius: 0;
        color :#c02323;
    }

    .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 50%)
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #191919;
        background: transparent;
    }

    .menu-cart {
        display: inline-block;
        position: absolute;
        width: auto;
        top: 10px;
        left: 10px;
        font-size: 1.8rem;
        cursor: pointer;
        color: #191919;
        background: transparent;
    }
    

    .fa-times {
        color: #191919;
        font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        padding: 1.5rem;
        margin: 2rem auto;
        border-radius:  4px;
        width: 80%;
        background: #4ad9ea;
        text-decoration: none;
        color: #191919;
        font-size: 1.5 rem;
    }

    .nav-links-mobile:hover {
        background: #191919 ;
        color: #6568F4;
        transition: 250ms;
    }
}

