.modalBackground {
    width: 100%;
    height: 100%;
    background: rgba(235, 235, 235, 0.61);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top:0;
    left:0;

  }
  
  .modalContainer {
    top: 50%;
    left: 50%;

    width: 300px;
    height: 300px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(235, 235, 235, 0.38) 0px 5px 15px;
    display: flex;
    flex-direction: column;
  }
  
  .modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 6px;

  }

  .title > p {
    font-family: 'Marcellus', sans-serif;
    font-weight: 400;
    color: #191919;
    line-height: 16px;
    font-size: 16px;
    letter-spacing: 1px;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }
  
  .modalContainer .body {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
  }
  
  .modalContainer .modalFooter {
    flex: 30%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 18px;
    margin-right: 18px;
  }
  

  
