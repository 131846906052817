.CosulMeu {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    align-content: center;
    margin-top: 30px;
    margin-bottom: 30px;    
    z-index: 1;
}

.CosulMeuSelector {
}


.CosulMeuSelector::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
}

.CosulMeuSelector > ul {
    padding: 0;
    list-style: none;
    height: 47px;
    display: flex;
    align-items: center;
    z-index: 0;
}

.CosulMeuInactiveLink {
    display: inline-block;
    width: 33.33%;
    text-align: center;
    height: 100%;
    padding-top: 10px;
    border-bottom: 2px solid #e5e5e5;
}

.CosulMeuSelector > ul > li:hover {
    display: inline-block;
    width: 33.33%;
    text-align: center;
    border-bottom: 2px solid #c02323;

    color: #c02323;
    

}


.CosulMeuActiveLink {

    display: inline-block;
    width: 33.33%;
    text-align: center;
    height: 100%;
    padding-top: 10px;
    border-bottom: 2px solid #c02323;
}


.CosulMeuSelector > ul > li > a {
    margin-right: 5px;
    margin-left: 5px;
    color: #191919;
    padding: 10px 0px;
    text-decoration: none;
    vertical-align: middle; 

    font-family: 'Marcellus';
    font-size: 24px;
    line-height: 27px;
    color: #191919;
}


.CosulMeuSelector > ul > li > a > img {
    width: 16px;
    vertical-align: baseline;
    margin-right: 5px;
    padding: 0;
    border: 0;
    outline: 0;
}

@media screen and (max-width: 960px) {
    .CosulMeuSelector > ul > li > a {
        margin-right: 5px;
        margin-left: 5px;
        color: #191919;
        padding: 10px 0px;
        text-decoration: none;
        vertical-align: middle; 
    
        font-family: 'Marcellus';
        font-size: 11px;
        line-height: 16px;
        color: #191919;
    }

    .CosulMeuSelector > ul > li:hover {
        display: inline-block;
        width: 33.33%;
        text-align: center;
        border-bottom: 2px solid #e5e5e5;

    }

    .CosulMeuActiveLink {

        display: inline-block;
        width: 33.33%;
        text-align: center;
        height: 100%;
        padding-top: 10px;
        border-bottom: 2px solid #e5e5e5;
    }
  
    .CosulMeuInactiveLink {
        display: inline-block;
        width: 33.33%;
        text-align: center;
        height: 100%;
        padding-top: 10px;
        border-bottom: 2px solid #e5e5e5;
    }
    

    
}