.producer {
    width: 50rem;
    padding: 1rem;
    background: #fff;
    /*box-shadow: 0 1px 4px rgba(0,0,0, 0.4);*/
    border: 1px solid #ebebeb;
    /*margin: 20px auto;*/
    margin-left: 15px;
    margin-bottom: 20px;
    margin-right: 15px;
    display: flex;
    flex-direction: row;
/*    display: flex;
    flex-direction: row;
    justify-content: center;
    */
/*    flex-direction: column;
    justify-content: space-between;
    */
}



.producer_name > h1
{
    font-family: 'Marcellus', sans-serif;
    color: #191919;
    line-height: 32px;
    font-size: 20px;
    letter-spacing: 1.2px;
}

.producer_name > p {
    font-family: 'Libre Baskerville';
    font-size: 14px;
    color: #595959;
    letter-spacing: 0.7px;
    line-height: 27px;
}

@media screen and (max-width: 960px) {
    .producer {
        flex-direction: column;
        padding: 0rem;
        margin-left: 12px;
        margin-right: 12px;
        width: auto;
    }

    .producer_name > h1 {
        text-align: center;
    }

    .producer_name > p {
        text-align: center;
    }
}