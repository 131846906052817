.AboutUs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-self: stretch;

    padding-left: 15px;
    padding-right:  15px;
    height: fit-content;
    width: fit-content;
    margin-right: auto;
    margin-left:auto;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 1200px;
}

.AboutUs_Image {
    flex: 38.2%;
}


.AboutUs_Image > img {
    width: 100%;
    border-radius: 8px;
    margin-top: 9px;
}


.AboutUS_Text {
    flex: 61.8%;
    width: 50%;
    font-family: 'Libre Baskerville', sans-serif;
    line-height: 24px;
    color: #595959;
    text-align: left;
    padding-left: 9px;
}


.AboutUS_Text > h1 {
    font-family: 'Marcellus', sans-serif;
    font-weight: 400;
    color: #191919;
    line-height: 28px;
    font-size: 36px;
    margin-bottom: 29px;
}

.AboutUS_Text > h1:hover {
    color :#c02323;;
}

.VillarsFontaine {
    font-family: 'Libre Baskerville', sans-serif;
    line-height: 24px;
    color: #595959;
    text-align: left;
    margin-top: 24px;
}


.VillarsFontaine_Flex {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-self: stretch;

    padding-right:  15px;
    height: fit-content;
    width: fit-content;
    margin-right: auto;
    margin-left:auto;
    margin-top: 20px;
    max-width: 1200px;
}

.VillarsFontaine_Text {
    flex: 50%;
    width: 50%;
}

.VillarsFontaine > h1 {
    font-family: 'Marcellus', sans-serif;
    font-weight: 400;
    color: #191919;
    line-height: 28px;
    font-size: 36px;
    margin-bottom: 33px;
}

.VillarsFontaine > h1:hover { 
    color :#c02323;;
}


.VillarsFontaine_Image {
    flex: 50%;
}

.VillarsFontaine_Image > img {
    width: 100%;
    border-radius: 8px;
}

.Beaujolais {
    font-family: 'Libre Baskerville', sans-serif;
    line-height: 24px;
    color: #595959;
    text-align: left;
    margin-top: 24px;
}

.Beaujolais_Flex {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-self: stretch;

    padding-right:  15px;
    height: fit-content;
    width: fit-content;
    margin-right: auto;
    margin-left:auto;
    margin-top: 20px;
    max-width: 1200px;
}

.Beaujolais_Image {
    flex: 50%;
}

.Beaujolais_Image > img{
    width: 100%;
    border-radius: 8px;
}

.Beaujolais_Text {
    flex: 50%;
}

.Beaujolais > h1 {
    font-family: 'Marcellus', sans-serif;
    font-weight: 400;
    color: #191919;
    line-height: 28px;
    font-size: 36px;
    margin-bottom: 33px;
}

.Beaujolais > h1:hover { 
    color :#c02323;;
}


.Beaujolais_Text > p {
    margin-top: 9px;
    margin-left: 9px;
}


@media screen and (max-width: 960px) {
    .AboutUs {
        flex-direction: column;
    }
    .AboutUS_Text {
        width: 100%;
    }

    .AboutUS_Text > h1 {
        margin-bottom: 21px;
        text-align: center;
    }

    .VillarsFontaine > h1 {
        text-align: center;
    }
    .VillarsFontaine_Flex {
        flex-direction: column;
    }

    .VillarsFontaine_Text {
        width: 100%;
    }

    .Beaujolais > h1{
        text-align: center;
    }

    .Beaujolais_Flex {
        flex-direction: column;
    }

    .Beaujolais_Text {
        width: 100%;
    }
}