.Cart {
    max-width: 1200px;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 72px;

}

.CartTable {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    border: 1px solid #dee2e6;
    text-align: left;
    border-collapse: collapse;

}


.CartTableTHead {
    margin: 0px;
    padding:0px;
    border: 0px;
    outline: 0px;
    box-sizing: border-box;
}

.CartTableHeader {
    box-sizing: border-box;
    padding: 20px 0;
    vertical-align: bottom;
    
    border: 1px solid #dee2e6;
    border-bottom: 2px solid #dee2e6;
    background-color: #f2f4f5;

    text-align: center;
    font-family: 'Libre Baskerville', sans-serif;
    line-height: 24px;
    font-size: 14px;
    font-weight: normal;
}

.CartTableXHeader {
    
    box-sizing: border-box;
    padding: 20px 0;
    vertical-align: bottom;

    border: 1px solid #dee2e6;
    border-bottom: 2px solid #dee2e6;
    background-color: #f2f4f5;

    font-size: 26px;
    color: #191919;
    text-align: center;
    font-family: 'Libre Baskerville', sans-serif;
    font-weight: normal;
}

.CartTableImageCell {
    padding: 18px 8px;
    vertical-align: center;
    border: 1px solid #dee2e6;
}


.CartTableImageCell > img { 
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 22px;
}


.CartTableProductNameCell {
    padding: 40px;
    vertical-align: middle;
    border: 1px solid #dee2e6;
    font-family: 'Libre Baskerville', sans-serif;
    line-height: 24px;
    font-size: 14px;
    font-weight: normal;
}

.CartTablePriceCell {
    padding: 40px 0px;
    vertical-align: middle;
    text-align: center;
    border: 1px solid #dee2e6;
    font-family: 'Libre Baskerville', sans-serif;
    line-height: 24px;
    font-size: 14px;
    font-weight: normal;
    color: #c02323;
}


.CartTableQuantityCell
{
    vertical-align: middle;
    text-align: center;
    padding: 40px 0px;

    border: 1px solid #dee2e6;


    font-family: 'Libre Baskerville', sans-serif;
    line-height: 24px;
    font-size: 14px;
    font-weight: normal;
}

.CartTableQuantityCell > ul
{
    display: block;
    list-style: none;
    padding: 0;
    
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.CartTableQuantityCell > ul > li
{
    display: inline-block;
    position: relative;
    margin-right: 5px;
}

.CartTableQuantityCell > ul > li >i
{
    cursor: pointer;
}

.CartTableQuantityCell > ul > li::before {
}

.fa {
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.CartTableTotalPriceCell {
    padding: 40px 0px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #dee2e6;
    font-family: 'Libre Baskerville', sans-serif;
    line-height: 24px;
    font-size: 14px;
    font-weight: normal;
    color: #c02323;
}


.CartTableDeleteCell {
    vertical-align: middle;
    color: #191919;
    padding: 18px 8px;
    text-align: center;

    border: 1px solid #dee2e6;

    font-family: 'Libre Baskerville', sans-serif;
    font-weight: normal;
    font-size: 26px;
}

.CartTableDeleteCell > p:hover {
    cursor: pointer;
}

.TotalCart {
    max-width: 1200px;
    align-content: right;
    margin-left: auto;
    margin-right: auto;
    margin-top: 90px;

    display: flex;
    flex-direction: row;


}

.CartOnLeft {
    flex:70%;
}

.CartEnd {
    font-family: 'Libre Baskerville', sans-serif;
    font-weight: normal;
    line-height: 24px;
    font-size: 14px;
    color: #595959;

    justify-content: flex-end;
    align-self: flex-end;
    flex:30%;
}


.CartEnd > h2 {
    font-family: 'Marcellus', sans-serif;
    font-weight: 400;
    color: #191919;

    margin-bottom: 20px;

    font-size: 24px;
    line-height: 32px;
}


.WrapCart {
    padding: 10px 10px;
    border: 1px solid #a6a6a6;
}

.Subtotal {
    width: 100%;
    display: inline-block;
}

.Subtotal > span {
    float: left;
    display: inline-block;
    min-width: 216px;
}

.Subtotal > p {
    float: right;
    display: inline-block;
    color: #c02323;
    margin-bottom: 12px;
    line-height: 26px;
}

.Shipping {
    width: 100%;
    display: inline-block; 
}

.Shipping > span {
    float: left;
    display: inline-block;
    min-width: 216px;
}

.Shipping > p {
    float: right;
    display: inline-block;
    color: #c02323;
    margin-bottom: 12px;
    line-height: 26px;
}

.Total {
    width: 100%;
    display: inline-block;  
    position: relative;
    padding-top: 15px;
}

.Total::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #a6a6a6;
}

.Total > span {
    float: left;
    display: inline-block;
    min-width: 216px;
}

.Total > p {
    float: right;
    display: inline-block;
    color: #c02323;
    margin-bottom: 12px;
    line-height: 26px;
}


.CheckOutButtonCosulMeu {
    margin-top: 12px;
    float: right;
}


@media screen and (max-width: 960px) {

    .CartTable {
        width: 96%;
        margin-bottom: 1rem;
        margin-left: auto;
        margin-right: auto;
        color: #212529;
        border: 1px solid #dee2e6;
        text-align: left;
        border-collapse: collapse;
    }

    .TotalCart {
        width: 96%;
        align-content: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 60px;
    
        display: flex;
        flex-direction: row;
    }

    .CartOnLeft {
        display: none;
    }

    .CartTableProductNameCell {
        padding: 6px;
    }
}