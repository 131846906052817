* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }
  
  .home,
  .services,
  .sign-up {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .products {
    max-width: 1300px;
    margin: 1rem auto;

  }

  .products_title {
    font-size: 1.5rem;
    color: #171717;
    margin-bottom: 1rem;
    margin-left: 8px;
  }

  .products_list {
    /*display: grid;*/
    /*grid-template-columns: repeat(4, 1fr);*/
    
    display: flex;
    /*margin: 0 auto; */
    justify-content: space-between;
    flex-wrap: wrap;
    align-self: stretch;

    align-items: flex-end;
    align-content: space-around;
  }
  
  @media screen and (max-width: 960px) {
    .products_list {
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: nowrap;
    }
}
