.footer {
    background-image: url('../../images/footer.jpg');
    
    height: 245px;
    max-height: 245px;

    margin-top: 10px;
}



.footer_links {
    display: block;
    text-align: center;
    box-sizing: border-box;
}

.footer_li {
    display: inline;
    padding-left: 24px;
    padding-right: 12px;
}

.footer_links > ul{
    margin-left: 27px;
}

.footer_li::after {
    content: "|";
    color: #a6a6a6;
    transform: translateY(-50%);
    box-sizing: border-box;
    width: 1px;
    height: 11px;
    padding-left: 12px;
    /*
    position: absolute;
    top: 50%;
    right: 0;
    */
}

/*
.footer_li::before {
    content: "|";
}
*/
.footer_href {
    text-decoration: none;
    font-family: 'Marcellus';
    font-size: 14px;
    line-height: 36px;
    color: #a6a6a6;
    padding-right: 10px;
}

.footer_href:hover {
    color: #c02323;
}

.footer_li_last {
    display: inline;
    padding: 0 3px; 
}

.footer_li_last::after {
    display : none;
}

.contactData {
    display: flex;
    justify-content: center;
    margin-top: 9px;
    color: #a6a6a6;
    margin-left: 36px;
}

.contactData > p {
    margin-left: 9px;
    font-family: 'Marcellus';
    font-size: 14px;
    line-height: 15px;
    margin-right: 12px;
}

.contactData > a {
    margin-left: 9px;
    font-family: 'Marcellus';
    font-size: 14px;
    line-height: 15px;
    margin-right: 12px;
    color: #a6a6a6;
}

.contactData > a:link {
    text-decoration: none;
  }