.producers {
    max-width: 1900px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    flex-wrap: wrap;

    align-items: flex-start;
    align-content: space-around;

}

.producers_container > h1 {
    font-family: 'Marcellus', sans-serif;
    color: #191919;
    line-height: 48px;
    font-size: 24px;
    letter-spacing: 1.2px;
    margin-left: 15px;
}

.producers_container > p {
    font-family: 'Libre Baskerville';
    font-size: 14px;
    color: #595959;
    letter-spacing: 0.7px;
    line-height: 27px;
    margin-left: 15px;
    margin-bottom: 15px;
}

@media screen and (max-width: 960px) {
    .producers {
        flex-direction: column;
    }

    .producers_container > h1 {
        margin-left: 0px;
        text-align: center;
    }

    .producers_container > p {
        margin-left: 0px;
        text-align: center;
    }
}