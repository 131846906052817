.OrderComplete {
    margin-top: 72px;
}   

.OrderComplete > p {
    margin-bottom: 12px;
    line-height: 26px;
    color: #595959;

    font-family: 'Libre Baskerville', sans-serif;
    line-height: 24px;
    font-size: 14px;
}


.OrderComplete > ul {
    list-style: none;

    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    font-style: inherit;
    vertical-align: baseline;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    background: transparent;
}

.OrderComplete > ul > li {
    font-family: 'Libre Baskerville', sans-serif;
    line-height: 24px;
    font-size: 14px;
    color: #595959;

    display: inline-block;
    position: relative;
    margin-right: 106px;
}

.OrderComplete > ul > li > h3{
    font-family: 'Marcellus', sans-serif;
    line-height: 20px;
    font-size: 16px;
    font-weight: 400;
    color: #191919;
}

.OrderComplete > ul > li > p{
    font-family: 'Libre Baskerville', sans-serif;
    line-height: 24px;
    font-size: 14px;
    font-weight: 400;
    color: #595959;

    margin-top: 14px;
    margin-bottom: 12px;
}

.OrderComplete > ul > li:after{
    content: "";
    position: absolute;
    top: 4px;
    right: -23px;
    width: 1px;
    height: 35px;
    background-color: #a6a6a6;;
}

.OrderSummaryTable {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 2px solid #a6a6a6;
    margin-top: 35px;
}


.OrderSummaryTableRow  {
    border-bottom: 1px solid #c2c2c2;
    color: #595959;
}

.OrderSummaryTableProductName {
    font-family: 'Libre Baskerville', sans-serif;
    font-size: 14px;
    line-height: 24px;
    padding: 25px 40px 24px 10px;
}

.OrderSummaryTableTotal {
    font-family: 'Libre Baskerville', sans-serif;
    line-height: 21px;
    font-size: 14px;
    padding: 0px 5px 0 48px;
}

.OrderSummaryTableGrandTotalText {
    padding: 22px 40px 19px 10px;
    font-family: 'Libre Baskerville', sans-serif;
    font-size: 14px;
    line-height: 24px;
    color: #111;
    font-weight: 600;
}

.OrderSummaryTableTotal {
    font-family: 'Libre Baskerville', sans-serif;
    line-height: 21px;
    font-size: 14px;
    padding: 0px 5px 0 48px;
}

.OrderSummaryTableGrandTotal {
    font-family: 'Libre Baskerville', sans-serif;
    line-height: 21px;
    font-size: 14px;
    padding: 0px 5px 0 48px;
    font-weight: 600;
}

@media screen and (max-width: 960px) {

    .OrderComplete > ul {
        margin-left: 6px;
    }

    .OrderComplete > p {
        margin-left: 6px;
    }

    .OrderComplete > ul > li {
    
        display: block;
        position: relative;
        margin-right: 27px;
    }

    .OrderComplete > ul > li:after{
        display: none;
    }
}