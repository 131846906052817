.product {
    width: 36rem;
    padding: 1rem;
    background: #fff;
    /*box-shadow: 0 1px 4px rgba(0,0,0, 0.4);*/
    border: 1px solid #ebebeb;
    box-shadow: 0 0 9px #ebebeb;
    /*margin: 20px auto;*/
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
/*    display: flex;
    flex-direction: row;
    justify-content: center;
    */
/*    flex-direction: column;
    justify-content: space-between;
    */
    height: 13rem;
    flex-shrink: 0;
}

.product_image_info{
    display: flex;
    flex-direction: row;
    flex: 80%;
}

.ProductImage {
    flex:6%;
    margin-left: 12px;
    margin-right: 12px;
}
.ProductImage > img {
    height: 170px;
    border-radius: 8px;
}

.product_info {
    margin-top: 9px;
    /*margin-bottom: 9px;*/
    max-width: 50rem;
    display: flex;
    flex-direction: column;
    flex:61.8%;
    width: fit-content;
}

.product_title {
    overflow: hidden;
    font-family: 'Marcellus', sans-serif;
    font-weight: 400;
    color: #191919;
    line-height: 32px;
    font-size: 20px;
    letter-spacing: 1.2px;
    text-align: left;
    flex: 15%;
}

.product_description {
    font-family: 'Libre Baskerville';
    font-size: 15px;
    color: #595959;
    letter-spacing: 0.6px;
    text-align: left;
    line-height: 27px;
    flex: 15%;
}

.product_region {
    font-family: 'Marcellus', sans-serif;
    /*font-weight: 400;*/
    color: #191919;
    line-height: 32px;
    font-size: 20px;
    letter-spacing: 1.2px;
    text-align: left;
    flex: 24%;
}

.product_params {
    flex: 12%;
    display: flex;
    align-items: left;
    justify-content: left;
}

.aging_potential_icon {
    width: 1.5rem;
    height: 1.5rem;
}

.aging_potential_number {
    width: 4rem;
    height: 1.5rem;
    text-align: left;
    margin-right: 3px;
}
.aging_potential_number > p{
    font-family: 'Marcellus', sans-serif;
    /*font-weight: 400;*/
    color: #191919;
    line-height: 18px;
    font-size: 18px;
    letter-spacing: 0.7px;

    margin-left:6px;
    margin-top: 3px;
}


.grape_variety_icon {
    width: 1.5rem;
    height: 1.5rem;
}

.grape_variety_name {
    height: 1.5rem;
    width: auto;
    margin-left: 3px;
    text-align: left;
}

.grape_variety_name > p {
    margin-top: 3px;

    font-family: 'Marcellus', sans-serif;
    /*font-weight: 400;*/
    color: #191919;
    line-height: 18px;
    font-size: 18px;
    letter-spacing: 0.7px;
}


.organic {
    width: 3rem;
    height: 1.5rem;
    text-align: center;
}

.organic > img {
    height: inherit;
    width: 1.5rem;
}

.product_price {
    font-weight: bold;
    text-align: center;
    font-family: 'Marcellus', sans-serif;
    font-weight: 400;
    color: #191919;
    line-height: 32px;
    font-size: 20px;
    letter-spacing: 1.2px;
    {/*width: 8rem;*/}
}

.PriceAndButton {
    margin-top: 6px;
    flex:20%;
    display: flex;
    flex-direction: column;
    flex-flow: column;
    align-content: flex-end;
    align-items: flex-end;
}

.product_price {
    flex: 80%;

}

.BuyButton {
    flex: 20%;
}



@media screen and (max-width: 960px) {
    .product {
        width: 21rem;
        display: flex;
        flex-direction: column;
        height: auto;
        margin:auto;
        margin-top: 18px;
        box-shadow: 0 0 9px #ebebeb;
    }

    .product_image_info{
        display: flex;
        flex-direction: column;
        flex: 66%;
    }
    
    .ProductImage {
        flex:26%;
    }

    .product_info {
        margin-top: 9px;
        /*margin-bottom: 9px;*/
        display: flex;
        flex-direction: column;
        flex:61.8%;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }


    .ProductImage > img {
        height: 170px;
        border-radius: 8px;
        display: block;
        margin-left: auto;
        margin-right: auto;

    }

    
    .aging_potential_icon {
        height: 1rem;
        width: 1rem;
        margin-top: 3px;
    }

    .aging_potential_number {
        width: 3rem;
        height: 1.5rem;
        text-align: center;
        margin-right: 0px;
        margin-top: 3px;
    }

    .aging_potential_number > p{
        font-family: 'Marcellus', sans-serif;
        /*font-weight: 400;*/
        color: #191919;
        line-height: 12px;
        font-size: 15px;
        letter-spacing: 0.6px;
    
        margin-left:3px;
        margin-top: 6px;
    }
    
    .grape_variety_icon {
        height: 1rem;
        width: 1rem;
        margin-top: 4px;
    }

    .grape_variety_name > p{
        margin-top: 9px;
    
        font-family: 'Marcellus', sans-serif;
        /*font-weight: 400;*/
        color: #191919;
        line-height: 12px;
        font-size: 15px;
        letter-spacing: 0.6px;
    }

    .organic {
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
        margin-left: 6px;
        margin-top: 6px;
    }

    .organic > img {
        height: inherit;
        width: 1.5rem;
    }
    

    .product_title {
        overflow: hidden;
        font-family: 'Marcellus', sans-serif;
        font-weight: 400;
        color: #191919;
        line-height: 27px;
        font-size: 18px;
        letter-spacing: 0.6px;
        text-align: center;
        flex: 18%;
        margin-top: 9px;
    }

    .product_description {
        font-family: 'Libre Baskerville';
        font-size: 15px;
        color: #595959;
        letter-spacing: 0.6px;
        text-align: center;
        line-height: 33px;
        flex: 18%;
    }
    
    .product_region {
        font-family: 'Marcellus', sans-serif;
        font-weight: 400;
        color: #191919;
        line-height: 27px;
        font-size: 18px;
        letter-spacing: 0.6px;
        text-align: center;
        flex: 18%;
    }
    
    .product_params {
        flex: 18%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 6px;
    }

    .PriceAndButton {
        margin-top: 6px;
        border-top: 1px solid #ebebeb;

        display: flex;
        flex-direction: row;
        padding-top: 12px;
    }

    .product_price {
        text-align: left;
        flex: 61.8%;
        padding-bottom: 6px;
    }

    .BuyButton {
        flex: 38.2%;
    }

 }