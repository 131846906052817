
.Terms {
    margin-top: 24px;
    max-width: 1200px;
}

.Terms >  h1 {
    font-family: 'Marcellus', sans-serif;
    font-weight: bold;
    color: #191919;
    line-height: 48px;
    font-size: 32px;
    letter-spacing: 1.2px;

    text-align: center;
    margin-bottom: 24px;
}

.Terms >  h2 {
    font-family: 'Marcellus', sans-serif;
    font-weight: bold;
    color: #191919;
    line-height: 32px;
    font-size: 20px;
    letter-spacing: 1.2px;

    margin-left: 120px;
}
.Terms > p {
    font-family: 'Libre Baskerville';
    font-size: 14px;
    color: #595959;
    letter-spacing: 0.7px;
    line-height: 27px;

    margin-left: 120px;
    margin-top: 9px;
    margin-bottom: 9px;
}

@media  screen and (max-width: 960px) {

    .Terms >  h2 {
        font-family: 'Marcellus', sans-serif;
        font-weight: bold;
        color: #191919;
        line-height: 32px;
        font-size: 20px;
        letter-spacing: 1.2px;
        margin-left: 6px;
    }
    .Terms > p {
        font-family: 'Libre Baskerville';
        font-size: 14px;
        color: #595959;
        letter-spacing: 0.7px;
        line-height: 27px;
    
        margin-top: 9px;
        margin-bottom: 9px;
        margin-left: 6px;
    }
}